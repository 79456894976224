<template>
    <v-card 
        tile 
        class="pa-3 ma-10"
    >
        <v-card-title>過去実績値チャート</v-card-title>
        <v-row>
            <v-col cols="12" class="mb-4">
                <time-series-chart
                    :duct="duct"
                    :current-yw="currentYw"
                    :initial-rel-time-range="{
                        yw: currentYw,
                        range: 10
                    }"
                    @click:bar="showRankingChart"
                ></time-series-chart>
                <!--<time-series-chart
                    :duct="duct"
                    :current-yw="currentYw"
                    :initial-abs-time-range="[
                        { year: 2022, week: 27 },
                        { year: 2022, week: 43 }
                    ]"
                    @click:bar="showRankingChart"
                ></time-series-chart>-->
            </v-col>
        </v-row>
        <v-divider id="chart2"></v-divider>
        <v-slide-y-transition>
            <v-row v-if="rankingChartShown">
                <v-col cols="12" class="mt-4">
                    <ranking-chart
                        v-bind="rankingChartProps"
                        @click:bar="showIpChart"
                        @click:close="closeRankingChart"
                    ></ranking-chart>
                </v-col>
            </v-row>
        </v-slide-y-transition>
        <v-divider id="chart3"></v-divider>
        <v-slide-y-transition>
            <v-row v-if="ipChartShown">
                <v-col cols="12" class="mt-4">
                    <ind-prod-chart
                        v-bind="ipChartProps"
                        @click:close="closeIpChart"
                    ></ind-prod-chart>
                </v-col>
            </v-row>
        </v-slide-y-transition>
    </v-card>
</template>
<script>
import TimeSeriesChart from './TimeSeriesChart'
import RankingChart from './RankingChart'
import IndProdChart from './IndProdChart'
export default {
    components: { 
        TimeSeriesChart,
        RankingChart,
        IndProdChart
    },
    data:() => ({
        currentYw: {},
        rankingChartShown: false,
        rankingChartProps: {},
        ipChartShown: false,
        ipChartProps: {},
    }),
    props: ['duct'],
    methods: {
        showRankingChart(obj) {
            this.rankingChartShown = true;
            this.rankingChartProps = { 
                duct: this.duct, 
                currentYw: this.currentYw, 
                ...obj 
            };
            setTimeout(() => { this.$vuetify.goTo('#chart2'); }, 100);
        },
        showIpChart(obj) {
            this.ipChartShown = true;
            this.ipChartProps = {
                duct: this.duct,
                currentYw: this.currentYw,
                initialRelTimeRange: { yw: this.rankingChartProps.initialYw, range: 10 },
                initialDataType: this.rankingChartProps.initialDataType,
                ...obj
            };
            setTimeout(() => { this.$vuetify.goTo('#chart3'); }, 500);
        },
        closeRankingChart() { this.rankingChartShown = false; },
        closeIpChart() { this.ipChartShown = false; }
    },
    created() {
        this.duct.invokeOnOpen(async () => {
            this.duct.addEventHandler(this.duct.EVENT.WEEK_GET_INFO, { success: (rid, eid, data) => {
                this.currentYw = { year: data.now.year, week: data.week };
            }});
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.WEEK_GET_INFO, { time: new Date().getTime()/1000 });
        });
    }
}
</script>
